import React, {useState, useEffect} from 'react'
import Layout from './Layout'
import Phone from './Phone'
import OTP from './OTP'
import Details from './Details'
import Interests from './Interests'
import cogoToast from 'cogo-toast'




const FreeSession1 = () => {
    return (
        <Layout>
            <div class = 'd-none d-lg-block'>
                <div class = "card shadow" style = {{backgroundColor: 'rgb(236 236 236)', minHeight: '500px', zIndex: '0'}}>
                    <div class = "card-body p-5">
                        <h1 class='text-center'>Book your free demo</h1>
                        <FreeSession />
                    </div>
                </div>
            </div>
            <div class = 'd-block d-lg-none'>
                <FreeSession />
            </div>
        </Layout>
    )
}





const FreeSession = () => {

    

    const [step, setStep] = useState(1);
    const [data, setData] = useState({
        phone: '',
        countryCode:'',
        otp: '',
        name: '',
        email: '',
        password: '',
        state: '',
        ageGroup: '',
        instrument: ''
    })

    useEffect(() => {
        if(step === 2){
            window.gtag('event', 'conversion', {
                'send_to': 'AW-324844382/un8tCOuy1fYCEN728poB',
            });
        }
        if(step === 3){
            cogoToast.success("You are almost there!", {
                hideAfter: 4
            })
            window.gtag('event', 'conversion', {
                'send_to': 'AW-324844382/3g93CNuogPcCEN728poB',
            });
        }
        if(step === 4){
            window.gtag('event', 'conversion', {
                'send_to': 'AW-324844382/oi64CJya1vYCEN728poB',
            });
        }
    }, [step])

    console.log(data)
    return (
        <div>
            <ul class="progressbar">
                <li class = {`${step >=1 ? 'active' : ''}`}></li>
                <li class = {`${step >=3 ? 'active' : ''}`}></li>
                <li class = {`${step >=4 ? 'active' : ''}`}></li>
            </ul>
            {step === 1 && <Phone 
                data = {data} 
                setData = {setData}
                setStep = {setStep}
            />}
            {step === 2 ? <OTP 
                phone = {data.phone}
                setStep = {setStep}
                setData = {setData}
            /> : ''}
            {step === 3 ? <Details 
                data = {data}
                setData = {setData}
                setStep = {setStep}
            /> : ''}
            {step === 4 ? <Interests 
                data = {data}
                setData = {setData}
                setStep = {setStep}
            /> : ''}
        </div>
    )
}
export default FreeSession1




