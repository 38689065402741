import axios from 'axios'

const submitResponse =  async (state) => {
        try{
            const scriptURL = "https://script.google.com/macros/s/AKfycbwbw7WCakYZGpymhQBDc8JcbYWoWTOJPvfDH1cpyJPKMuT7dC0H/exec";
            const formData = new FormData()
            formData.append('type', state.type || "")
            formData.append('phone', state.phone || "")
            formData.append('utm_campaign', state.utm_campaign || "")
            formData.append('utm_source', state.utm_source || "")
            formData.append('utm_medium', state.utm_medium || "")
            formData.append('utm_content', state.utm_content || "")
            await axios.post(scriptURL, formData)
            return;
        } catch(err){
            throw err;
        }
}



export default submitResponse