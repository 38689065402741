import React, {useState} from 'react'
import {validateEmail} from '../utilis/validation'
import axios from '../services/axios'
import {AiOutlineEye, AiOutlineEyeInvisible} from 'react-icons/ai'

const Details = ({data, setData, setStep}) => {

    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState(Array(3).fill(false));
    const [showPassword, setShowPassword] = useState(false);


    const validate = () => {
        let new_errors = Array(6).fill(false)
        if(data.name.length < 3) new_errors[0] = true;
        if(!validateEmail(data.email)) new_errors[1] = true;
        if(data.password.length < 8) new_errors[2] = true;
        setErrors(new_errors)
        if(new_errors.includes(true)) return false;
        else return true;
      }

    const nextStep = async (e) => {
        e.preventDefault()
        if(!validate()) return;
        try{
            setLoading(true)
            const {firstName, lastName} = splitFirstLast(data.name.trim())
            await axios.post('/api/auth/onboarding', {
                firstName,
                lastName,
                email: data.email.trim(),
                password: data.password,
                phone: data.phone
            })
            setLoading(false)
            setStep(4);

        } catch(err){
            setLoading(false)
            console.log(err)
        }
    }


    return (
        <form class=""> 
            <div class = "form-group">
                <label class = 'h2 mb-4'>Create your learning profile</label>
                <div class=  'form-group'>
                    <label>Name</label>
                    <input 
                        className = {`form-control ${errors[0] && 'is-invalid'}`} 
                        value = {data.name} 
                        onChange = {e => {
                            setData(prev => ({...prev, name: e.target.value}))
                        }}
                    />
                    <div class = 'invalid-feedback'>Please enter a valid name</div>
                </div>
                <div class=  'form-group'>
                    <label>Email</label>
                    <input 
                        className = {`form-control ${errors[1] && 'is-invalid'}`} 
                        value = {data.email} 
                        onChange = {e => {
                            setData(prev => ({...prev, email: e.target.value}))
                        }}
                    />
                    <div class = 'invalid-feedback'>Please enter a valid email</div>
                </div>
                <div class=  'form-group'>
                    <label>Password</label>
                    <div className="input-group input-group-merge">
                        <input
                            type={showPassword ? "text" : "password"}
                            className={`form-control form-control-appended ${errors[2] && 'is-invalid'}`}
                            placeholder='Password'
                            value={data.password}
                            onChange={(e) => setData(prev => ({...prev, password: e.target.value}))}
                        />
                        <div className="input-group-append">
                            <span className="input-group-text" onClick={() => setShowPassword(!showPassword)}>
                            {!showPassword ? (<AiOutlineEye className = 'pointer' size = {15} />) : (<AiOutlineEyeInvisible className = 'pointer' size = {15}/>)}
                            </span> 
                        </div>
                        <div class = 'invalid-feedback'>Please enter a valid password</div>
                    </div>
                </div>
            </div>
            <button class="btn btn-block btn-primary mt-5" disabled = {loading} onClick = {e => nextStep(e)} style = {{fontSize: '18px'}}>
                {loading && <span class = 'spinner-border spinner-border-sm mr-2'></span>}
                Next
            </button>
        </form>
    )
}

export default Details



function splitFirstLast(name) {
    return {
        // eslint-disable-next-line
      firstName: name.indexOf(' ') !== -1 && name.substring(0, name.indexOf(' ')) || name,
      // eslint-disable-next-line
      lastName: name.indexOf(' ') !== -1 && name.substring(name.indexOf(' ') + 1) || ''
    }
}