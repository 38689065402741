import React, {useState} from 'react'
import axios from '../services/axios'
import Select from 'react-select'
import cogoToast from 'cogo-toast'
import submitResponse from '../utilis/submitResponse'

const Details = ({data, setData, setStep}) => {

    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState(Array(3).fill(false));

    const validate = () => {
        const new_errors = Array(3).fill(false)
        if(data.instrument.length === 0) new_errors[0] = true;
        if(data.state.length === 0) new_errors[1] = true;
        if(data.ageGroup.length === 0) new_errors[2] = true;
        setErrors(new_errors)
        if(new_errors.includes(true)) return false;
        else return true;
    }

    const nextStep = async (e) => {
        e.preventDefault();
        if(!validate()) return;
        try{
            setLoading(true)
            await axios.post('/api/auth/interests', {
                phone: data.phone,
                state: data.state.value,
                instrument: data.instrument.map(item => item.value).join(', '),
                ageGroup: data.ageGroup
            })
            await submitResponse({
                phone: data.phone, 
                type: 'Final'
            })
            cogoToast.success("Thank you, we will reach out to you shortly!")
            window.gtag('event', 'conversion', {
                'send_to': 'AW-324844382/h9XlCOO01vYCEN728poB',
            });
            window.setTimeout(() => window.location.assign('https://www.dhunguru.com/instructors') , 1000)
            
            setLoading(false)

        } catch(err){
            setLoading(false)
            console.log(err)
        }
    }


    return (
        <form class=""> 
            <div class = "form-group">
                <label class = 'h2 mb-4'>Create your learning profile</label>
                <div class = "form-group">
                    <label>Your Instrument</label>
                    <Select
                        value={data.instrument}
                        onChange={(e) => {setData(prev => ({...prev, instrument: e}))}}
                        options={instruments}
                        isMulti = {true}
                    />
                    {errors[0] && <div class = 'invalid-feedback d-block'>Please select a instrument</div>}
                </div>
                <div class=  'form-group'>
                    <label>State</label>
                    <Select 
                        value={data.state}
                        onChange={(e) => {setData(prev => ({...prev, state: e}))}}
                        options={states.map(item => ({value: item, label: item}))}
                    />
                    {errors[1] && <div class = 'invalid-feedback d-block'>Please select your state</div>}
                </div>
                <div class=  'form-group'>
                    <label>Age Group</label>
                    <div>
                        {age_groups.map(item => <span 
                            onClick ={() => setData(prev => ({...prev, ageGroup: item.value}))} 
                            className = {`btn mr-3 px-4 mb-2 btn-${data.ageGroup === item.value ? 'primary':'white'}`}
                        >{item.label}</span>)}
                    {errors[2] && <div class = 'invalid-feedback d-block'>Please select your age group</div>}
                    </div>
                </div>
                
            </div>
            <button class="btn btn-block btn-primary mt-5" disabled = {loading} onClick = {e => nextStep(e)} style = {{fontSize: '18px'}}>
                {loading && <span class = 'spinner-border spinner-border-sm mr-2'></span>}
                Go to your dashboard
            </button>
        </form>
    )
}

export default Details



const instruments = [
    { value: 'guitar', label: "Guitar"},
    { value: 'piano', label: "Piano"},
    { value: 'drums', label: "Drums"},
    {value: 'keyboard', label: "Keyboard" },
    {value: 'violin', label: "Violin" },
    {value: 'ukulele', label: "Ukulele" },
    {value: 'cajon', label: "Cajon" },
    {value: 'harmonium', label: "Harmonium" },
    {value: 'flute', label: "Flute" },
    {value: 'tabla', label: "Tabla" },
    {value: 'veena', label: "Veena" },
    {value: 'trumpet', label: "Trumpet" },
    {value: 'mridangam', label: "Mridangam" },
    {value: 'sitar', label: "Sitar" },
    {value: 'singing', label: "Singing" },
    {value: 'songWriting', label: "Song Writing" },
    {value: 'musicComposition', label: "Music Production" },
    {value: 'musicTheory', label: "Music Theory" },
    {value: 'rap', label: "Rap" },
    {value: 'dJing', label: "DJing" },
    {value: 'dhol', label: "Dhol" },
    {value: 'harmonica', label: "Harmonica" },
    {value: 'mandolin', label: "Mandolin" },
    {value: 'doubleBass', label: "Double Bass" },
    {value: 'cello', label: "Cello" },

]


const age_groups = [
    {value: '5-15', label: "5-15"},
    {value: '16-25', label: "16-25"},
    {value: '26-35', label: "26-35"},
    {value: '35-50', label: "35-50"},
    {value: '50+', label: "50 above"},
]



const states = [ 
    "Andhra Pradesh",
    "Arunachal Pradesh",
    "Assam",
    "Bihar",
    "Chhattisgarh",
    "Goa",
    "Gujarat",
    "Haryana",
    "Himachal Pradesh",
    "Jammu and Kashmir",
    "Jharkhand",
    "Karnataka",
    "Kerala",
    "Madhya Pradesh",
    "Maharashtra",
    "Manipur",
    "Meghalaya",
    "Mizoram",
    "Nagaland",
    "Odisha",
    "Punjab",
    "Rajasthan",
    "Sikkim",
    "Tamil Nadu",
    "Telangana",
    "Tripura",
    "Uttarakhand",
    "Uttar Pradesh",
    "West Bengal",
    "Andaman and Nicobar Islands",
    "Chandigarh",
    "Dadra and Nagar Haveli",
    "Daman and Diu",
    "Delhi",
    "Lakshadweep",
    "Puducherry"
]