import React, {useState, useEffect} from 'react'
import {cleanPhone} from '../utilis/validation'
import axios from '../services/axios'
import submitResponse from '../utilis/submitResponse'

const OTP = ({phone, setStep, setData}) => {

    const [otp, setOtp] = useState('');
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [timer, setTimer] = useState(20);

    console.log(phone)
    useEffect(() => {
        if (!timer) return;
        const intervalId = setInterval(() => {
            setTimer(prev => prev - 1);
          }, 1000);
        return () => clearInterval(intervalId);
    }, [timer])

    const nextStep = async (e) => {
        e.preventDefault()
        try{
            setLoading(true);
            const cleanedPhone = cleanPhone(phone);
            const res = await axios.post('/api/auth/submit-otp', {
                phone: cleanedPhone,
                otp: otp
            });
            submitResponse({
                phone: cleanedPhone, 
                type: 'OPT_SUCCESS'
            })
            if(!res.data.email || res.data.email === '' || res.data.email === undefined){
                setStep(3)
            } else setStep(4)
            setLoading(false)
        }
        catch(err){
            setLoading(false)
            console.log(err)
        }
    }

    const resendOtp = async () => {
        try{
            await axios.post('/api/auth/resend-otp', {phone});
            setTimer(20)
        } catch(err){
            console.log(err)
        }
    } 


    return (
        <form class=""> 
            <div class = "form-group pb-6">
                <label class = 'h3 font-weight-normal'>Enter the code we have sent to {phone} <span className = 'btn-link pointer' onClick = {() => setStep(1)}>Edit</span></label>
                    <input 
                        style = {{height: '50px'}} 
                        className = {`form-control ${error && 'is-invalid'}`} 
                        value = {otp} 
                        onChange = {e => {
                            if(error) setError(false);
                            setOtp(e.target.value)
                        }}
                    />
                
                {error && <div class = 'invalid-feedback d-block'>Please enter a valid phone number</div>}
                <div class = 'text-muted h3 mt-4'>Haven’t received an SMS? {timer < 1? <span className = 'btn-link pointer' onClick = {() => resendOtp()}>Send Again</span> : `Resend verification code in ${timer}s`}</div>
            </div>
            <button class="btn btn-block btn-primary mt-6" disabled = {loading} onClick = {e => nextStep(e)} style = {{fontSize: '18px'}}>
                {loading && <span class = 'spinner-border spinner-border-sm mr-2'></span>}
                Verify and Proceed
            </button>
            <div class = 'text-center'>
            <div class = 'px-lg-6 text-center small mt-3'>By Continuing, you agree to Dhunguru's<br/>
            <a href = "https://dhunguru.com/legal#tnc-students">Terms of Use </a>, <a href="https://dhunguru.com/legal#privacy">Privacy Policy</a> and <a href="https://dhunguru.com/refund-policy">Refund Policy</a></div>
                </div>
        </form>
    )
}

export default OTP
