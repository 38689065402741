import React, { useState } from 'react'
import { validatePhone, cleanPhone } from '../utilis/validation'
import axios from '../services/axios'
import submitResponse from '../utilis/submitResponse'
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";

const Phone = ({ data, setData, setStep }) => {

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false)
  const [phoneInput, setPhoneInput] = useState();


  const nextStep = async (e) => {
    e.preventDefault()
    if (validatePhone(data.phone)) {
      setLoading(true);
      const cleanedPhone = cleanPhone(data.phone);
      setData({ ...data, phone: cleanedPhone });
      const campaign = {}
      var self = window.location.toString();
      var querystring = self.split("?");
      if (querystring.length > 1) {
        var pairs = querystring[1].split("&");
        for (let i in pairs) {
          var keyval = pairs[i].split("=");
          campaign[keyval[0]] = keyval[1]
        }
      }
      if (querystring.length > 1) {
        if (querystring[1] == "url=https://www.google.com/asnc/aHR0cHM6Ly9vbmxpbmUuZA/&adgroupid=143446714939&gb=1") {
          campaign["utm_campaign"] = "143446714939"
          campaign["utm_source"] = "google"
        }
        if (querystring[1] == "url=https://www.google.com/asnc/aHR0cHM6Ly9vbmxpbmUuZA/&adgroupid=143577408455&gb=1") {
          campaign["utm_campaign"] = "143577408455"
          campaign["utm_source"] = "google"
        }
      }
      const res = await axios.post('/api/auth/phone-signup', {
        phone: cleanedPhone,
        countryCode: "+"+data.countryCode,
        utms: {
          ...campaign
        }
      });
      submitResponse({
        phone: data.countryCode + cleanedPhone,
        type: 'Phone',
        ...campaign
      })
      if (res.data === 'SENT') {
        setStep(2);
      }
      setLoading(false)
    } else {
      setError(true);
    }
  }

  const handlePhoneChange = (e, otherData) => {
    const reducedPhone = e.replace(otherData.dialCode, "");
    setPhoneInput(e);
    setData({
      ...data,
      phone: reducedPhone,
      countryCode: otherData?.dialCode
    });
  }


  return (
    <form class="">
      <div class="form-group pb-4">
        <label class="h3 font-weight-normal">
          Register your number to receive class updates
        </label>
        <div className="input-group ">
          {/* <div className="input-group-prepend">
              <span className="input-group-text font-weight-bold">+91</span>
            </div> */}
          {/* <input
              type="number"
              style={{ height: "50px" }}
              className={`form-control ${error && "is-invalid"}`}
              value={phone}
              onChange={(e) => {
                if (error) setError(false);
                setPhone(e.target.value);
              }}
            /> */}
          <PhoneInput
            country={"in"}
            value={phoneInput}
            onChange={handlePhoneChange}
            containerStyle={{ width: "100%" }}
            inputClass="form-control"
            containerClass={`${error && "is-invalid"}`}
            inputStyle={{
              minWidth: "100%",
              borderColor: `${error ? "red" : ""} `,
            }}
          />
        </div>

        {error && (
          <div class="invalid-feedback d-block">
            Please enter a valid phone number
          </div>
        )}
        <div class="text-muted h3 mt-4">
          A verification code (OTP) will be sent to your registered mobile
          number
        </div>
      </div>
      <button
        class="btn btn-block btn-primary mt-0"
        disabled={loading}
        onClick={(e) => nextStep(e)}
        style={{ fontSize: "18px" }}
      >
        {loading && (
          <span class="spinner-border spinner-border-sm mr-2"></span>
        )}
        Send verification code
      </button>
      <div class="text-center">
        <div class="px-lg-6 text-center small mt-3">
          By Continuing, you agree to Dhunguru's
          <br />
          <a href="https://dhunguru.com/legal#tnc-students">
            Terms of Use
          </a>,{" "}
          <a href="https://dhunguru.com/legal#privacy">Privacy Policy</a> and{" "}
          <a href="https://dhunguru.com/refund-policy">Refund Policy</a>
        </div>
      </div>
    </form>
  );
}

export default Phone
