import FreeSession from "./Home";



const App = () => {

  return (
          <FreeSession />
  );
}

export default App;




