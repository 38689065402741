import React, { useEffect, useRef } from 'react'
import Swiper, { Navigation, Pagination, Autoplay, Scrollbar, Parallax, EffectFade, Controller } from 'swiper'
Swiper.use([Navigation, Pagination, Autoplay, Scrollbar, Parallax, EffectFade, Controller]);



const Layout = ({ children }) => {
    return (
        <div class="d-flex align-items-center" style={{ minHeight: '100vh' }}>
            <div className='container py-sm-5 px-0 px-md-6 pt-0 ' id="free-session">
                <div class='d-none d-lg-block'>
                    <img
                        alt=""
                        src="https://dhunguru-static-data-logos.s3.ap-south-1.amazonaws.com/logo.png"
                        class='img-fluid '
                        style={{ position: 'fixed', maxHeight: '80px', left: '40px', top: '40px' }}
                    />
                    {/* <img src='https://dhunguru-static-data-logos.s3.ap-south-1.amazonaws.com/logo.png' className="navbar-brand-img mx-auto" alt="..." /> */}

                </div>
                <div class='d-block d-lg-none text-center mt-n3'>
                    <img
                        alt=""
                        src="https://dhunguru-static-data-logos.s3.ap-south-1.amazonaws.com/logo.png"
                        class='img-fluid mt-5'
                        style={{ maxHeight: '50px' }}
                    />
                </div>
                <div class="row px-md-6 px-lg-0 pt-0 py-lg-6" style={{}}>
                    <div class="col-lg-6 mb-md-0 mb-grid-gutter pr-lg-6 ">
                        <div className='d-none d-lg-block mt-6 pt-6'>
                            <ValueProps />
                        </div>
                    </div>
                    <div class="col-lg-6 col-12 px-5">
                        <h1 class="d-none d-lg-block font-weight-bolder text-center mb-5 mt-5 px-n5 mt-n2" style={{ fontSize: '35px' }}>
                            1:1 <div style={{ color: '#ff6700', display: 'inline-block' }}>live music classes</div> for all age groups
                        </h1>
                        <h1 class="d-block d-lg-none font-weight-bolder text-center mb-5 mt-5 " style={{ fontSize: '32px' }}>
                            1:1 <div style={{ color: '#ff6700', display: 'inline-block' }}>live music classes</div> for all age groups
                        </h1>
                        {children}
                    </div>
                    <div class="col-lg-6 col-12">
                        <div className='d-block d-lg-none mt-4 p-6' style={{ backgroundColor: 'rgb(230 230 230)' }}>
                            <ValueProps />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}



export default Layout



const ValueProps = () => {

    const swiper = useRef(null)
    const swiperMe = useRef(null)

    useEffect(() => {
        swiperMe.current = new Swiper(swiper.current, {
            slidesPerView: 1,
            spaceBetween: 10,
            speed: 600,
            loop: true,
            autoplay: {
                delay: 2000,
                disableOnInteraction: true
            },
            pagination: {
                el: '.swiper-pagination',
                disableOnInteraction: true
            },
            breakpoints: {
                320: {
                    slidesPerView: 1,
                    spaceBetween: 0
                },
                480: {
                    slidesPerView: 1,
                    spaceBetween: 0
                },
                640: {
                    slidesPerView: 1,
                    spaceBetween: 0
                }
            }
        })
    }, [])


    return (
        <div class="swiper mt-2 pt-0" >
            <div class="swiper-container" ref={swiper}>
                <div class="swiper-wrapper mb-6">
                    <div className='swiper-slide ' >
                        <div className="text-center">
                            <img src="/assets/1.svg" alt="" class='img-fluid mb-4 ' style={{ height: '270px' }} />
                            <h3 className="h2 mb-2">Book your first session with us, absolutely FREE.</h3>
                        </div>
                    </div>
                    <div className='swiper-slide '>
                        <div className="text-center">
                            <img src="/assets/2.svg" alt="" class='img-fluid mb-4 ' style={{ height: '270px' }} />
                            <h3 className="h2 mb-2">Purchase as low as 1 session at a time. Pay as you go.</h3>
                        </div>
                    </div>
                    <div className='swiper-slide '>
                        <div className="text-center">
                            <img src="/assets/4.svg" alt="" class='img-fluid mb-4 ' style={{ height: '270px' }} />
                            <h3 className="h2 mb-2">Learn at your own pace with a customized course plan.</h3>
                        </div>
                    </div>
                    <div className='swiper-slide '>
                        <div className="text-center">
                            <img src="/assets/3.svg" alt="" class='img-fluid mb-4 ' style={{ height: '270px' }} />
                            <h3 className="h2 mb-2">Practice all you want with full access to all session recordings.</h3>
                        </div>
                    </div>
                </div>
                <div class="swiper-pagination mt-10"></div>
            </div>
        </div>
    )
}

